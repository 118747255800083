import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import LogicContext from "../../Context/LogicContext";
import { createModal } from "react-modal-promise";
import GeneralModal from "../../helpers/GeneralModal";
import SelectSelect from "./StatusSelect";
import { FileUploader } from "react-drag-drop-files";
import { settingsService } from "../../services/settingsService";
import { GetEditFeatureAtom, SetEditFeatureAtom } from "../../store/settingsAtom";
import moment from "moment";
import { GetProductCatFeatureAtom } from "../../store/productCategoryFeatureAtom";
import ProductFeatureSelect from "./ProductFeatureSelect";
import Switch from '@mui/material/Switch';
import VisibleToggle from "./VisibleToggle";








function AddProductFeature() {
    const navigate = useNavigate();
    const myPromiseModal = createModal(GeneralModal);
    const dataAtomStore = GetProductCatFeatureAtom();
    const { register, setValue, getValues, handleSubmit, control, reset, formState: { errors } } = useForm();
    const logicContext = useContext(LogicContext);
    const [infinityCheck, setInfinityCheck] = useState(false);
    const [mobileUrl, setMobileUrl] = useState('');
    const [webUrl, setWebUrl] = useState('');
    const setEditFeature = SetEditFeatureAtom();
    const getEditFeature = GetEditFeatureAtom();
    const [file, setFile] = useState(null);
    const [imageSource, setImageSource] = useState(null);
    const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];
   



    useEffect(() => {
        setValue('feature_status_id', 1);
        setValue('app_visible_id', 1);
        setValue('web_visible_id', 1);
        console.log('editable ', getEditFeature);

        if (getEditFeature?.Ref_No) {
            console.log('Editable ', getEditFeature);
            setValue('feature_status_id', getEditFeature.Feature_Status_Id);
            setValue('feature_name', getEditFeature.Feature_Name);
            setValue('feature_action_url_id', getEditFeature.Feature_Action_URL_Id);
            setMobileUrl(getEditFeature?.Feature_Action_URL_Mobile);
            setWebUrl(getEditFeature?.Feature_Action_URL_Web);
            setValue('slide_order', getEditFeature.Slide_Order);
            setValue('feature_image_url', getEditFeature.Feature_Image_URL);
            setValue('app_visible_id', getEditFeature.App_Visible_Id);
            setValue('web_visible_id', getEditFeature.Web_Visible_Id);
            setImageSource(getEditFeature.Feature_Image_URL);
        }

        return () => {
            setEditFeature({});
        }
    }, []);


    function startDateFunction() {
        let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
        if (getEditFeature?.Start_Date !== undefined) {
            defaultDate = moment(getEditFeature?.Start_Date).format("YYYY-MM-DD");
        }

        return (
            <>
                <input
                    className="bg-inputColor h-[6vh] pl-3  w-[35vw] "
                    placeholder="enter start date"
                    name="start_date" type="date"
                    defaultValue={defaultDate}
                    {...register("start_date", {
                        required: "Start date is required",
                    })}
                />
            </>

        )
    }

    const onMobileVisible = (val) => {
        console.log(val);
        setValue('app_visible_id', val);
    }

    const onWebVisible = (val) => {
        console.log(val);
        setValue('web_visible_id', val);

    }


    function endDateFunction() {
        let defaultDate = moment(Date.now()).format("YYYY-MM-DD")
        if (getEditFeature?.End_Date !== undefined) {
            defaultDate = moment(getEditFeature?.End_Date).format("YYYY-MM-DD")
        }

        return (
            <>
                <input
                    className="bg-inputColor h-[6vh] pl-3  w-[35vw] "
                    placeholder="enter start date"
                    name="end_date" type="date"
                    defaultValue={defaultDate}
                    {...register("end_date", {
                        required: "Start date is required",
                    })}
                />
                <br />


            </>

        )
    }

    const onSubmit = async () => {
        const formValues = getValues();
        if (!imageSource) {
            return logicContext.showToast('Please upload feature image', 'error');
        }

        //  Start edit
        if (getEditFeature?.Ref_No) {
            await myPromiseModal({
                title: "Update record!", body: `Please confirm your action.`,
                showInput: false, placeHolder: ''
            }).then((res) => {
                if (res.decision === true) {

                    const formData = new FormData();
                    if (infinityCheck) {
                        delete formValues?.end_date;
                    }
        console.log('LOG... ', { ...formValues, ref_no: getEditFeature?.Ref_No });

                    formData.append('file', file);
                 
                    var formObject = JSON.stringify({ ...formValues, ref_no: getEditFeature?.Ref_No });
                    formData.append('formObject', formObject);
                    settingsService.updateFeature(formData).then(res => {
                        if (res?.data?.length && res?.data[0]?.message_id === 1) {
                            logicContext.showToast(res?.data[0].message_text, 'success');
                            navigate(-1);
                        }
                    });
                }
            });

            // end Edit
        } else {

            //  New record
            await myPromiseModal({
                title: "Save record!", body: `Please confirm your action.`,
                showInput: false, placeHolder: '' }).then((res) => {
                if (res.decision === true) {

                    const formData = new FormData();
                    if (infinityCheck) {
                        delete formValues?.end_date;
                    }
                    formData.append('file', file);
                    var formObject = JSON.stringify(formValues);
                    formData.append('formObject', formObject);
                    settingsService.submitNewFeature(formData).then(res => {
                        console.log('log ', res?.data);
                        if (res?.data?.length && res?.data[0]?.message_id === 1) {
                            logicContext.showToast(res?.data[0].message_text, 'success');
                            reset();
                        }
                    });
                }
            });

            // end new record

        }



    }


    function formError(error) {
        console.log('errr ', error);
        logicContext.showToast("Form error", "error");
    }

    const changeHandler = (event) => {
        setInfinityCheck(prev => {
            return !prev
        });
    }

    const onStatusSelect = (eveData) => {
        setValue('feature_status_id', eveData.value);
    }


    const onImageLoad = (event) => {
        const imageWidth = event?.target?.naturalWidth;
        const imageHeight = event?.target?.naturalHeight;
        // setTempHeight(imageHeight);
        // setTempWidth(imageWidth);
        // if (imageHeight !== BASE_HEIGHT){
        //   return logicContext.showToast(`Image height of ${imageHeight}h is unacceptable. Image width and height must be ${BASE_HEIGHT}h and ${BASE_WIDTH}w`, 'error');
        // }else if(imageWidth !== BASE_WIDTH){
        //   return logicContext.showToast(`Image width of ${imageWidth}w is unacceptable. Image width and height must be ${BASE_HEIGHT}h and ${BASE_WIDTH}w`, 'error');
        // }
    }


    const handleImageChange = (imageFile) => {
        setFile(imageFile);
        if (imageFile) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImageSource(reader.result);
            });

            reader.readAsDataURL(imageFile);
        }
    };


    const onFeatureSelect = (data) => {
        console.log('eveeee ', data);

        setValue('feature_action_url_id', data?.Action_URL_Id);
        setWebUrl(data?.Action_URL_Web);
        setMobileUrl(data?.Action_URL_Mobile);

    }



    return (
        <>
         <div className="jumbotron bg-light">
  <div className="flex justify-between items-center">
    <h6 className="text-lg mb-3 font-bold">Add New</h6>
    <div className="flex justify-center mb-2">
      <img className="m-1 rounded-full" onLoad={(e) => onImageLoad(e)} src={imageSource} alt="" width="200px" />
    </div>
  </div>
  <form onSubmit={handleSubmit(onSubmit, formError)}>
    <section className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <label className="border-2  p-2 text-sm text-filterTextColor font-bold mb-2">Feature Name:</label>
        <input
          className="bg-inputColor h-[6vh] pl-3 w-full"
          placeholder="enter feature name"
          type="text"
          name="feature_name"
          {...register("feature_name", {
            required: "Feature name is required",
          })}
        />
        <small className="text-red-500">
          {Boolean(errors["feature_name"]?.message) && String(errors["feature_name"]?.message)}
        </small>
      </div>
      <div>
        <label className="border-2 p-2 text-sm text-filterTextColor font-bold mb-2">Upload Image:</label>
        <FileUploader
          className="uploader"
          id="uploader"
          handleChange={handleImageChange}
          name="file"
          types={fileTypes}
          multiple={false}
        />
      </div>
      <div>
        <ProductFeatureSelect onFeatureSelect={onFeatureSelect} defaultVal={getEditFeature?.Feature_Name} list={dataAtomStore.action_url_info} />
        <div className="flex items-center">
          <label className="border-2 p-2 text-sm text-filterTextColor font-bold mr-2">Mobile Url:</label>
          <span className="text-primary">{mobileUrl}</span>
          <VisibleToggle visible={getEditFeature?.Web_Visible_Id} onVisibleChange={onMobileVisible} />
        </div>
        <div className="flex items-center">
          <label className="border-2 p-2 text-sm text-filterTextColor font-bold mr-2">Web Url:</label>
          <span className="text-primary">{webUrl}</span>
          <VisibleToggle visible={getEditFeature?.App_Visible_Id} onVisibleChange={onWebVisible} />
        </div>
      </div>
      <div>
        {/* Web here */}
      </div>
      <div>
        {startDateFunction()}
      </div>
      <div>
        {endDateFunction()}
        <div className="flex items-center">
          <input
            className="bg-inputColor mr-2"
            name="unlimited"
            type="checkbox"
            {...{
              checked: infinityCheck,
              onChange: changeHandler,
            }}
          />
          <label className="border-2 p-2 text-sm text-filterTextColor font-bold">Infinity</label>
        </div>
      </div>
      <div>
        <label className="border-2 p-2 text-sm text-filterTextColor font-bold mb-2">Slide Order:</label>
        <input
          className="bg-inputColor h-[6vh] pl-3 w-full"
          placeholder="enter slide order"
          type="number"
          name="slide_order"
          {...register("slide_order", {
            required: "Slide order is required",
          })}
        />
        <small className="text-red-500">
          {Boolean(errors["slide_order"]?.message) && String(errors["slide_order"]?.message)}
        </small>
      </div>
      <div>
        <label className="border-2 p-2 text-sm text-filterTextColor font-bold mb-2">Status:</label>
        <SelectSelect defaultVal={getEditFeature?.Feature_Status_Id} onStatusSelect={onStatusSelect} />
      </div>
    </section>
    <div className="flex justify-end">
      <button
        type="submit"
        className="rounded-lg px-10 hover:bg-sky-700 my-3 py-2 bg-blue-500 text-blue-100"
      >
        Submit
      </button>
    </div>
  </form>
</div>
        </>
    );
}

export default AddProductFeature;





