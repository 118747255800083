// import CustomerContext from "../Context/CustomerContext";
import { useEffect, useState } from "react";
import {  SetIVRHistoryStore, GetIVRHistoryStore } from '../../store/IVRAtom';
import { Grid } from "@mui/material";
import {  useParams } from "react-router-dom";
import moment from "moment/moment";



function IVRScroll(props) {

const getIVRHistoryList = GetIVRHistoryStore();
 const [tableData, setTableData] = useState([]); 
 const { id } = useParams();



 useEffect(()=> {
setTableData(getIVRHistoryList.map(ele => { return  {...ele, checked: false}} ))
 },[getIVRHistoryList]);



// const onCheckboxClick = async (e, index) => {
//   let element = tableData[index];
//   tableData[index] = { ...element, checked: e.target.checked };
//   await setTableData((prev) => {
//     return [...tableData];
//   });
//   props?.onSelection(tableData);
// };


// const manageStatus = (statusCode, statusName) =>{
//   let defaultClass = 'text-primary';

//   switch (statusCode) {
//     case 1:
//     defaultClass = 'color_1';
//       break;
//     case  2:
//     defaultClass = 'color_2';
//       break;
//     case 3:
//     defaultClass = 'color_3';
//       break;
//     case 4:
//     defaultClass = 'color_4';
//       break;
//     case 5:
//     defaultClass = 'color_5';
//       break;
  
//     case 6:
//     defaultClass = 'color_6';
//       break;
  
//     default:
//       break;
//   }


//       return (
//         <span className={`text-sm font-bold  ${defaultClass} `}>
//         {statusName}
//       </span>
     
//       )}




      return ( 
        <>
     <section className="w-30  z-0 mt-3 main2 	 border-2 b">

      {tableData?.map((info, index) => {
        return (

          <div key={index} onClick={() => props.viewItemDetails(info?.IVR_Id)}
            className={"flex hover:bg-filterColor border  "
              + (Number(id) === info?.IVR_Id ? "app_bg_color1 mb-2 show-cursor" : "mb-2 show-cursor")}
          >
            <Grid container spacing={1}>
              <Grid item xs={1}>
                {/* <label className="ml-1">
                  <input onChange={(e) => onCheckboxClick(e, index)} checked={info?.checked ? info?.checked : ''}
                    type="checkbox" className="w-[2vh] h-[2vh]" />
                </label> */}
              </Grid>
              <Grid 
                item xs={6}>
                <label className="ml-2 ">
                  <span className="subheading font-bold md:text-sm">Total : {info?.Total_Client} </span>{" "}
                  <br></br>
                  <span className="subheading font-bold md:text-sm">
                    <i className="font-bold" >{info?.Call_Date} </i>
                    {/* {moment(info.Call_Date).format(" DD-MM-YYYY:HH:mm:ss")} */}
                  </span>
                  <br></br>
                  {/* <div className="mt-2 mr-2" >{info?.Template_Name} </div> */}
                </label>
              </Grid>
              <Grid item xs={5}>

                <div>    <label className="ml-2" >
                  {/* {manageStatus(info?.Corporate_Approval_Status_Id, info?.Corporate_Approval_Status_Name)} */}
                  <div className="mt-2 mr-2" >{info?.Template_Name} </div>

                    </label></div>

              </Grid>
            </Grid>
          </div>

        )
      })

      }

    </section>
        </>
      );

}

export default IVRScroll;
