import React, { useRef, useContext } from "react";
import moment from "moment/moment";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import LogicContext from "../../Context/LogicContext";
import IVRScroll from "./IVRScroller";
import { notificationService } from "../../services/notification.service";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Box from '@mui/material/Box';


// import { useReactToPrint } from 'react-to-print';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const IVRServiceDetailsPage = () => {
  const [details, setDetails] = useState({});
  const {  id } = useParams();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [historyDetailsList, setHistoryDetailsList] = useState([]);
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];



  useEffect(() => {
  
       document.title = 'IVR history details';
    getHistoryDetails(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  
  const getHistoryDetails = async (id) => {
    // notificationService.getIVRDetails(id).then((res)=> {
    //     if(res.data?.length){
    //       setHistoryDetailsList(res.data);
    //       setDetails(res.data[0]);
    //     }
    // });

  };


  function viewItemDetails(id) {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`
    );
    getHistoryDetails(id);
  }





  return (
    <>
      <section className="  mt-2 ">
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <section className="mt-4 max-auto max-w-6xl d-flex flex-row-reverse ">
              <div className="flex flex-wrap justify-between md:">
                <div>
             
                  <div className="flex ">
                    {/* <button
                      onClick={deleteCurrentData}
                      style={{ borderRadius: "5px" }}
                      className="  px-3 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 ml-2"
                    >
                      <img src={trash} alt="" />
                    </button> */}
                  </div>
                </div>
              </div>
            </section>
          </Grid>
          

          <Grid item xs={12}>
            <section className="flex relative justify-between ">
              <IVRScroll
                // onSelection={onSelection}
                viewItemDetails={viewItemDetails}
              ></IVRScroll>

              <section className="w-full z-50  ">
              

                {historyDetailsList?.length  &&  
                <>
                  <Box component="section" className="mb-5 mt-5" sx={{ p: 2, border: '1px dashed grey' }}>
                    A Total of {historyDetailsList.length} IVR Calls was triggered with a trigger ID <span className="font-bold text-primary" >{id}</span>
                  </Box>
                </>
                }

                <ul class="list-group">
                  <li class="list-group-item active"> Total Call(s) : <span className="text-white ml-3 font-bold" >{historyDetailsList?.length} </span> </li>
                  <li class="list-group-item"> Trigger ID : <span className="text-slate-600 ml-3 font-bold"  >{id} </span> </li>
                  <li class="list-group-item"> Template Name : <span className="text-slate-600 ml-3 font-bold"  >{details?.Template_Name} </span> </li>
                  <li class="list-group-item"> Created By: <span className="text-slate-600 ml-3 font-bold"  >{details?.Encoded_By_Name} </span> </li>
                  {/* <li class="list-group-item">Vestibulum at eros</li> */}
                </ul>
                            
                   {/* Table */}
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
     
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="left">Phone No</StyledTableCell>
            <StyledTableCell align="left">Email</StyledTableCell>
            <StyledTableCell align="left">Type</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="left">Call Date</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historyDetailsList.map((row, index) => (
             <StyledTableRow key={row.name}>
             <StyledTableCell component="th" scope="row">
            
             <Link target="_blank"  className="text-primary" to={`/${2}/subscription/customers/customer-details/${row.Customer_Id}`} >{row.Customer_Full_Name} </Link> 
           </StyledTableCell>
        
           <StyledTableCell align="left">{row.Customer_Mobile_No}</StyledTableCell>
           <StyledTableCell align="left">{row.Customer_Email_Address}</StyledTableCell>
           <StyledTableCell align="left">{row.Customer_Type}</StyledTableCell>
           <StyledTableCell align="left">{row.IVR_Status}</StyledTableCell>
           {/* <StyledTableCell align="left">{moment(row.Call_Date).startOf('hour').fromNow()}</StyledTableCell> */}
           <StyledTableCell align="left">{moment(row.Call_Date).format(" DD-MM-YYYY:HH:mm:ss")}</StyledTableCell>
         </StyledTableRow>
       
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        {/* Table end */}
              </section>
            </section>
          </Grid>
        </Grid>
     
      </section>
    </>
  );
};

export default IVRServiceDetailsPage;
