import { createContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './GeneralSpinner.css';
import axios from "axios";
import { authenticationService } from "../services/authentication.service";
import  {spinnerService} from "../services/spinner.service";
import { UseCurrentMenuState, currentMenuListAtom } from "../store/storeMenuAtom";
import { useRecoilState } from "recoil";
import { authHeader } from "../helpers/authHeader";
import { handleResponse } from "../helpers/handle-response";

import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const LogicContext = createContext({
  showToast: (message, type) => { },
  getModule: () => { },
  moduleSelect: () => { },
  setModuleList: (modules) => { },
  getUserName: () => { },
  httpResponseManager: (responseData) => {},
});

export function LogicContextProvider(props) {
  const mountedRef =  React.useRef(true);
  const [ currentMenu, setCurrentMenu] = useRecoilState(currentMenuListAtom); 
  var currentUser = undefined;
  var customSpinner = false;
 let subscriber = authenticationService.currentUserSubject.subscribe(user => {
    currentUser = user;
  });

  
  const [showSpinner, setShowSpinner] = useState(false);
  const [moduleList, setModuleList] = useState('');
  

   useEffect(() => {
  spinnerService.spinnerSubject.subscribe(boolean =>  {
    if(mountedRef?.current){
      setShowSpinner(boolean);
    }
  });

  return ()=> {
    mountedRef.current = false;
    subscriber.unsubscribe();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function showToast(message, type) {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "info":
        toast.info(message);
        break;
      case "dark":
        toast.dark(message);
        break;
      case "warning":
        toast.warning(message);
        break
      default:
        toast.dark(message);
        break;
    }
  }


  useEffect(() => {
    if ( currentUser?.User_Id){
      axios.get('/get-modules/' + currentUser.User_Id).then((res) => {
        setModuleList(res.data.modules);
  
      })
    }
   
  }, [currentUser?.User_Id]);


  function moduleSelectHandler(Module_Id){
    axios.get(`/get-selected-menu/${Module_Id}`, authHeader()).then(res =>{
     if(mountedRef.current){
      setCurrentMenu(res.data['menu']);
     }
    } ).catch((err)=> {
    })
  }


  function setModuleListhandler(modules){
    setModuleList(modules);
  }


  function getModuleHandler(){
    return moduleList;
  }


  const httpResponseManager = (apiData) => {
    if(apiData){
        const {message_id, message_text, Message_Id} = apiData
        const optionCheck = message_id || Message_Id;
        switch (optionCheck) {
          case 1:
          case '1':
            return apiData 

          case 2:
          case '2':
             toast.warning(message_text);
             return apiData;

          case 3:
          case '3':
             toast.error(message_text);
             return apiData;

          default:
            toast.error(message_text || "connection error!");
        }
       
    }else{
      toast.error("Network error!");
    }
 
}


  const context = {
    showToast,
    getModule: getModuleHandler,
    moduleSelect: moduleSelectHandler,
    setModuleList: setModuleListhandler,
    httpResponseManager: httpResponseManager,

  };

  return (
    <LogicContext.Provider value={context}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner} >
         
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer theme="colored" />
      {props.children}
    </LogicContext.Provider>
  );
}


export default LogicContext;
