
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const priceService = {
     getPriceList,
     getPriceDetails,
     updateSingleItem,
     updateStatus
    
};





async function  getPriceList() {
    return  axios.get(`/inventory/price-list`, authHeader());
}

async function  getPriceDetails(id, product_id) {
    return  axios.get(`/inventory/price-details/${id}/${product_id}`, authHeader());
}
async function  updateSingleItem(data) {
    return  axios.put(`/inventory/update-single-item`, data, authHeader());
}


async function  updateStatus(data) {
    return  axios.put(`/inventory/update-single-status`, data, authHeader());
}

// async function  submitNewFeature(data) {
//     return  axios.post(`/settings/submit-new-feature`,data, authHeader());
// }

// async function  updateFeature(data) {
//     return  axios.put(`/settings/update-feature`,data, authHeader());
// }


// async function  statusChange(data) {
//     return  axios.put(`/settings/status-change`,data, authHeader());
// }

// async function  ActionUrlStatusChange(data) {
//     return  axios.put(`/settings/status-change-action-url`,data, authHeader());
// }


// async function  getFeatureDetails(id) {
//     return  axios.get(`/settings/feature-details/${id}`, authHeader());
// }


// async function  getHomePageFeature() {
//     return  axios.get(`/settings/homepage-feature`, authHeader());
// }


// async function  actionUrlDetails(id) {
//     return  axios.get(`/settings/action-url-details/${id}`, authHeader());
// }


// async function  newActionUrl(data) {
//     return  axios.post(`/settings/new-action-url`, data, authHeader());
// }


// async function  updateActionUrl(data) {
//     return  axios.put(`/settings/update-feature-action-url`,data, authHeader());
// }








