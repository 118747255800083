import { useEffect } from "react";
import ProductFeatureTable from './ProductFeatureTable';
import { SetProductCatFeatureAtom, GetProductCatFeatureAtom } from "../../store/productCategoryFeatureAtom";
import { Link } from "react-router-dom";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import RestartAlt from "@mui/icons-material/RestartAlt";
import { settingsService } from "../../services/settingsService";




function ProductFeaturePage() {
  const getFeatureList = GetProductCatFeatureAtom();
  const setFeatureList = SetProductCatFeatureAtom();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];




  const newFeatureSetup = () => {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
  }


  const fetchDataFromServer = () => {
    settingsService.getProductFeatureList().then(res => {
      console.log('P_FEData_List  ', res.data);
      if (res.data?.action_url_info?.length){
        setFeatureList(res.data);
      }
    });
  }




  return (
    <>
      <div className=" b">
        <section className="">
          <div className="flex justify-between  w-full  "  >
            <Tooltip title="Reload">
              <RestartAlt
                className="cursor ml-2 mt-1"
                onClick={() => fetchDataFromServer()}
                color="primary"
                fontSize="large"
              />
            </Tooltip>
            <button onClick={() => newFeatureSetup()}
              style={{ backgroundColor: "#476EF8" }}
              className="  rounded text-white text-sm font-[Poppins] py-2  px-3"
            >
              Add New
            </button>
          </div>



          <ProductFeatureTable list={getFeatureList.product_feature_info} ></ProductFeatureTable>
        </section>
      </div>

    </>

  );
}

export default ProductFeaturePage;
