import { createContext, useContext, useEffect } from "react";

import axios from "axios";
import { authHeader } from '../helpers/authHeader';
import { useRecoilState } from "recoil";
import {customerTableHeaderAtom, customerDetailsAtom, UseSetCustomerListData} from '../store/customerAtom';
import LogicContext from "./LogicContext";


const CustomerContext = createContext({
  getSingleCustomer: (customer_id) => { },
  saveCustomerTableHeader: (xmlString) => { },
  getCustomers: () => { },
  getCustomerTableHeader: () => { },
  getCustomers: () => { },
  
});

export function CustomerContextProvider(props) {
  const BASE_URL = process.env.REACT_APP_API_BASEURL_GET;

  const logicContext = useContext(LogicContext);
  const [ customerTableHeader, setCustomerTableHead] = useRecoilState(customerTableHeaderAtom); 
  // const [ curCustomerDetails, setCurCustomerDetails] = useRecoilState(customerDetailsAtom); 
  const setCustomersState = UseSetCustomerListData();




  function getCustomerTableheaderHandler(){
                  axios.get(BASE_URL + '/customer-table-menu',authHeader() ).then((res) => {
        setCustomerTableHead(res.data);
      }).catch((err)=> {})
  }



  function singleCustomerHandler(customer_id){
return axios.get(`/get-single-customer/${customer_id}`, authHeader());
  }





  function saveCustomerTableHeaderHandler(xmlString){
    var queryString = {"sql_ref_no":9,"c_code":1001,"user_id":"100001","user_token":"100200300","_xml2":`<root> ${xmlString} </root>`,"option" : "1"}
 
    axios.put('/update-customer-table-header', queryString, authHeader()).then(res => {
    });
        
  }



//  get customer list 
  async function  getCustomerHandler() {
    // var  userPref = {sql_ref_no : 6,  option : 4, c_code:1001}
        axios.get('/customer-list-cache', authHeader()).then(res => {
          console.log('cust_list ', res.data);
          if(res.data['customers'].length > 0){
            setCustomersState(res.data['customers']);
          }
         
        });

    }


//  get customer list 
  async function  activeCustomersHandler() {

        axios.get('/active-customer', authHeader()).then(res => {
          setCustomersState(res.data['customers']);
        });
    }

  
  const context = {
    getSingleCustomer: singleCustomerHandler,
    saveCustomerTableHeader: saveCustomerTableHeaderHandler,
    getCustomers : getCustomerHandler,
    getActiveCustomers : activeCustomersHandler,
    getCustomerTableHeader : getCustomerTableheaderHandler,
  };

  return (
    <CustomerContext.Provider value={context}>
    {props.children}
    </CustomerContext.Provider>
  );
}

export default CustomerContext;
