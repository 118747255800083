import { useEffect, useRef, useState } from "react";
// import {GetPurchaseOrderMasterData} from '../store/purchaseOrderAtom';
import { Grid } from "@mui/material";
import {  GetProductUrlListAtom } from "../../store/productUrlAtom";

import moment from "moment";
import { useLocation, useParams, useNavigate } from "react-router-dom";








function ProductActionUrlScroll(props) {
    const actionList = GetProductUrlListAtom();
    const mountRef = useRef(true)
    const [tableData, setTableData] = useState([]);
    const [checkedAll, setCheckAll] = useState(false);
    const { id } = useParams();

    const navigate = useNavigate();
    const { pathname } = useLocation();
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];



    useEffect(() => {
        mountRef.current = true;

        if (actionList?.length > 0) {
            setTableData(actionList.map((ele) => { return { ...ele, checked: false } }));
        }

        return () => {
            mountRef.current = false;
        }
    }, [actionList]);


    const onActionFilterPerform = (action) => {
        const option = action.target.value;
        if (Number(option) === 0) {
            return setTableData(actionList);
        }
        var newList = actionList.filter((item) => item.Sales_Order_Status_Id === Number(option));
        setTableData(newList);
    }


    const onCheckAllElement = async (selection) => {

        setCheckAll(prev => { return (!prev) });

        let newList = tableData?.map((item, i) => {
            return { ...item, checked: !selection }
        });

        await setTableData((prev) => {
            return [...newList];
        });
        // props?.onSelection(newList);
    }


    const onItemSelect = (id) => {
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`);
    }



    const onCheckboxClick = async (e, index) => {
        let element = tableData[index];
        tableData[index] = { ...element, checked: e.target.checked };
        await setTableData((prev) => {
            return [...tableData];
        });
        props?.onSelection(tableData);
    };


    const managePurchaseStatus = (status) => {
        let defaultClass = "color1";

        switch (status) {
            case "InActive":
                defaultClass = "color2";
                break;
            case "Active":
                defaultClass = "color3";
                break;
            case "Pending for Approval":
                defaultClass = "color4";
                break;
            case "Fulfilled":
                defaultClass = "color5";
                break;
            case "Rejected":
                defaultClass = "color6";
                break;
            case "Confirmed":
                defaultClass = "color1";
                break;
            case "Partially Billed":
                defaultClass = "color8";
                break;

            default:
                break;
        }

        return (
            <span className={`text-sm font-bold md:text-sm ${defaultClass} `}>
                {status}
            </span>
        );
    };



    return (
        <>

            <section className=" w-full  z-0  main	 border-2 b">
                {/* <div >
                    <label className=" mt-3 mb-3 ml-2 ">
                        <input onChange={(e) => onCheckAllElement(checkedAll)}
                            type="checkbox" className="w-[2vh] h-[2vh]"
                            checked={checkedAll ? checkedAll : ''} />
                        <span className="ml-2 font-bold " >Select all</span>
                    </label>
                </div> */}

                {tableData?.map((ele, i) => {
                    return (
                        <>
                            <Grid onClick={() => onItemSelect(ele?.Action_URL_Id)} key={i} container spacing={2}
                                className={" hover:bg-filterColor border-2 mb-2 show-cursor "
                                    + (Number(id) === ele?.Action_URL_Id ? "app_bg_color1" :
                                        "")}
                            >
                                <Grid item xs={2} className="">
                                    <label className="ml-2">
                                        <input
                                            type="checkbox" className="w-[2vh] h-[2vh]" />
                                    </label>
                                </Grid>
                                <Grid item xs={5}>

                                    <label className="" >
                                        <span className="text-sm  md:text-sm">

                                            {moment(ele?.Encoded_Date).format("DD/MM/YYYY")}
                                        </span> <br></br>
                                        <span className="text-md  text-primary">
                                            {ele?.Feature_Action_URL_Name}

                                        </span>
                                    </label>

                                </Grid>
                                <Grid item xs={5}>
                                    <div className=" w-full  flex mt-0.5 ">
                                        {/* <div className="text-md">
                                            {ele?.Encoded_By_Name}
                                        </div> */}

                                        <div className=" text-md  text-primary" >
                                            {managePurchaseStatus(ele?.Feature_Action_Status_Name)}
                                        </div>

                                    </div>
                                </Grid>

                            </Grid>

                        </>

                    )
                })}




            </section>
        </>
    );
}

export default ProductActionUrlScroll;
