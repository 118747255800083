
function GeneralDropDown(props) {





    return ( 
        <>
        
        <ul className="dropdown-menu w-100"aria-labelledby="dropdownMenuLink" >
                        {props?.dropown?.map((item, i) => {
                          return (
                          <span key={i}>
                                <li>
                              <a
                                onClick={() => props.optionSelect(item)}
                                className="dropdown-item"
                                href="#"
                              >
                                {" "}
                                {item?.Name}{" "}
                              </a>
                              
                            </li>
                          
                          </span>
                          );
                        })}

                        {
                          props.manage == true ? 
                          <li>
                          <div className="d-grid gap-2">
                             <button onClick={()=> props.manageList()} className="btn btn-sm btn-primary btn-block" type="button"> Manage list</button>
                             </div>
                             </li> 
                          :  null
                        }
                        
                      </ul>
                     
        </>
     );
}

export default GeneralDropDown;