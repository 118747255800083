
import { notificationService } from '../../services/notification.service';
import { GetMainIVRStore, SetMainIVRStore } from '../../store/IVRAtom';
import IVRCustomerSearch from './IVRCustomerSearch';
import { customerService } from '../../services/customer.service';
import React,{ useState, useEffect, useContext } from "react";
import { debounce } from "lodash";
import LogicContext from '../../Context/LogicContext';
import IVRCustomerTable from './IVRCustomerTable';
import GeneralModal from '../../helpers/GeneralModal';
import GeneralModalTimer from '../../helpers/GeneralModalTimer';
import { createModal } from "react-modal-promise";
import DoneIcon from '@mui/icons-material/Done';
import { useForm } from "react-hook-form";
import IVRFilter from './IVRFilter';
import { UploadOutlined } from '@ant-design/icons';
import IVRUploadModal from './IVRUploadModal';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import socketModule from '../../socketModule';





function IVRSystemTab() {
    const setDataStore = SetMainIVRStore();
    const myPromiseModal = createModal(GeneralModal);
    const myPromiseModalTimer = createModal(GeneralModalTimer);

    
    const getDataStore = GetMainIVRStore();
    const [searchResult, setSearchResult] = useState([]);
    const [selectedIVRTemplate, setSelectedIVRTemplate] = useState({});
    const [tableList, setTableList] = useState([]);
    const [jsonHeaders, setJsonHeaders] = useState([]);
    const logicContext = useContext(LogicContext);
    const { register, setValue, getValues, handleSubmit, control, reset, formState: { errors } } = useForm();




    useEffect(()=> {
        // console.log('Runnn', getDataStore);
        if(getDataStore?.total < 1){
            getTemplates();
        }
  
    },[getDataStore?.total]);




    useEffect(()=> {
      socketModule.on('ivr_process_schedule_completed', (data) => {
        console.log('Socket connect success ', data);
      });

        // Cleanup on component unmount
        return () => {
          socketModule.off('message');
        };
    },[]);


    const getTemplates = ()=> {
        notificationService.getSurvey().then((res)=> {
            if(res.data?.status === true){
                setDataStore(res.data);
            }
        });

    }


    const selectFromSearch = (customer) => {
      const item = tableList.find(cust => cust.Customer_Id === customer?.Customer_Id);
      if(item){
        return   logicContext.showToast('Customer already in the list! ', 'warning');
      }
      console.log(customer);
      setTableList((prevList)=> {
        return [...prevList, customer]
      });
      setSearchResult('');
    }


    const removeFromSelection = (customer)=> {
      const newList = tableList.filter((item)=> item?.Mobile !== customer?.Mobile);
      setTableList(newList);
    }


    const selectIVRTemplate = (template) => {
      setSelectedIVRTemplate(template);
    }


    const processIVR = async()=> {
      if(!selectedIVRTemplate?.name){
        return  logicContext.showToast('Please select a Template! ', 'error');
      }
      if(!tableList?.length){
        return  logicContext.showToast('At least one customer should be selected! ', 'error');
      }

      await myPromiseModalTimer({ title: "Process IVR", body: `Process ${selectedIVRTemplate?.name} Template  for ${tableList.length} customers`,
      showInput:false, placeHolder:'',showInput:false, placeHolder:''}).then((modalResult) => {
        console.log('RES ', modalResult);
        const {decision, dateValue, timeValue } =  modalResult;
          if (decision === true) {
           
            let numberStringList = [];
            tableList.forEach((item)=> { numberStringList.push( item?.Mobile) });

         
              let tempCust = {
                 timeValue:timeValue,
                 dateValue: dateValue,
                'name': selectedIVRTemplate.name,
                'identifier':  selectedIVRTemplate.identifier,
                "msisdns": numberStringList.toString()
              }

              let custTemp = '';
              tableList.map((cust)=> {
                custTemp += `<a><name> ${cust?.Name} </name><mobile>${cust?.Mobile}</mobile></a>`
              });
           
             
              notificationService.processSurvey(tempCust).then((res)=> {
                console.log('IVR_success', res.data );
               logicContext.showToast(`A total of ${tableList.length} IVR call(s) with  template name (${selectedIVRTemplate?.name}) is processing...`,'success')

                if(res.data?.status === true){
                  let serverData = {template_id: selectedIVRTemplate?.id,id: res.data.data?.id,xml_string:`<root>${custTemp}</root>`};

                  notificationService.submitIVRHistory(serverData).then((res)=> {
                    console.log('History_success ', res.data );
               logicContext.showToast(`A total of ${tableList.length} IVR call(s) has been saved to history`,'info');

                  }).catch((err)=> {
                    console.log('SUBMIT_ERROR ', err);
               logicContext.showToast(`A total of ${tableList.length} IVR call(s) was not saved to history`,'error');

                  })

                }else if(res.data?.schedule === true){
                  logicContext.showToast(`A total of ${tableList.length} IVR call(s) has been schedule to be processed on ${dateValue}:${timeValue}`,'success')

                }

            
                 
                }).catch(err => {
                  console.log('ERrrrorrr ',err);
                });
               
            // });

        
          }
        });

    }



    const debouncedSearch = React.useRef(
      debounce(async (event) => {
        processSearch(event);
      }, 1500)).current;


    function processSearch(search){
      if(search?.length > 2){
        customerService.getCustomerListSearch(search).then(res => {
          console.log('Search_result ', res.data);
          if(res?.data?.length > 0){
            return setSearchResult(res?.data);
          }else{
            logicContext.showToast('No available record for your search query! ', '');
            return setSearchResult([]);
          }
        }).catch((err)=> {
          console.log('ERERR ', err);
        })
      }else{
        setSearchResult([]);
      }
   
    }


  const onFilterSelect = async (value) => {
    console.log('Obs ', value);
  
    try {
      let response = await notificationService.notificationFilter({ option: value?.Filter_Option, notification_type: 2 });
      if (response?.data?.length > 0) {
        console.log('res   ', response?.data);
        // setNotifyList(prev => {
        //   return { ...prev, customers: response.data }
        // });
       let tempList =  response.data.map((item)=> {
        return {...item, Name: item.Customer_Full_Name, Mobile: item.Customer_Mobile_No}

        })
        setTableList(tempList);

      }else{
        logicContext.showToast('Search Criteria not available!',"warning");
      }

    } catch (error) {
      logicContext.showToast('Feature not available!');
    }

  }

  const clearList = ()=> {
    setTableList([]);
  }


  const onFileUpload = (event) => {
    let uploadedFile = event.target.files[0];
    const fileType = event.target.files[0]?.name.split('.')[1];
    let tempUpload = [];
    console.log('type ', fileType);
    // if(fileType !== 'csv' ||  fileType !== 'xlsx'){
    //   return logicContext.showToast('Only XLSX or CSV file is allowed! ', 'error');
    // } 

    if (fileType === 'csv') {
      Papa.parse(uploadedFile, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
            console.log('Parsed Results:', results);
            
            // Extract headers
            const firstRow = results.data[0];
            const headers = Object.keys(firstRow);
            setJsonHeaders(headers);

            // Convert to JavaScript array
            const jsArray = results.data.map(row => ({
                name: row[headers[0]],
                mobile: row[headers[1]],
                email: row[headers[2]]
            }));
                console.log('jsArray ', jsArray);
            const newList = jsArray.map((item)=> {
              return {...item , Name: item.name, Mobile: item.mobile }
            } );
            setTableList(newList);
        }
    });

    } else if (fileType === 'xlsx') { // For Excel file
      const file = event.target.files[0];
        const reader = new FileReader();
        
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });

            // Get the first sheet name
            const sheetName = workbook.SheetNames[0];
            
            // Convert the sheet to JSON
            const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

            // Convert JSON to JavaScript array
            const jsArray = sheetData.map(row => ({
                name: row['name'],
                mobile: row['mobile'],
                email: row['email']
            }));
            

            // setData(jsArray);
            const newList = jsArray.map((item)=> {
              return {...item , Name: item.name, Mobile: item.mobile }
            } );
            setTableList(newList);
        };

        reader.readAsBinaryString(file);
 
    }
  }



    return ( 
        <>

<div className="rounded-md">
  <h1 className=" text-center font-weight-bold">IVR  System</h1>
  <div className='row' >
      <div className='col-7' >

          Template Name <span className='text-primary font-bold text-uppercase' >{selectedIVRTemplate?.name}   </span>
          {selectedIVRTemplate?.audio_assets  &&
                    <audio controls key={selectedIVRTemplate?.id}>
                    <source src={selectedIVRTemplate?.audio_assets} key={selectedIVRTemplate?.id} type="audio/wav"/>
                </audio>
          
          }

          {!selectedIVRTemplate?.name  && 
           <span className="text-center text-danger" >No template is selected, select template from the list below.</span>
            }


      </div>
      <div className='col-3' >
        <button className="btn btn-primary" onClick={()=> processIVR()} >Process IVR <DoneIcon color='action' fontSize='large' /> </button>
      </div>

      <div className='col-2' >
      {/* <button className="btn btn-primary"  >  <UploadOutlined  style={{ fontSize: '25px', color: '#fff' }} /> </button> */}
      <IVRUploadModal  onFileUpload={onFileUpload} />

      </div>
  </div>
  <hr className="my-4" />
</div>

        <div className="container mt-1">
            <div className="row">
                <div className="col-3">
                <ul className="list-group">
                <li className="list-group-item active"> Template List </li>
                    {  getDataStore?.data?.data?.map((template)=> {
                        return (
                            <li className={"show-cursor list-group-item "
                              + (template?.id === selectedIVRTemplate?.id ? "app_bg_color1 mb-2 show-cursor" : "mb-2 show-cursor")}
                             onClick={()=> selectIVRTemplate(template)} > <span>{template?.id} </span> {template?.name} </li>
                        )
                    })}
                    
                
                </ul>
                </div>

                <div className="col-9">
                <div className="d-flex flex-row bd-highlight mb-3">
                
                <div className="p-2 bd-highlight">
                <IVRCustomerSearch debouncedSearch={debouncedSearch} />

          
                <div className="pt-2 mb-2 dropdown relative mx-auto text-gray-600">
                {(searchResult.length > 0) && 

                      searchResult.map((cust, i)=> {

                        console.log('look ', cust);
                        return (
                          <ul class="list-group">
                      <li  onClick={()=> selectFromSearch(cust)} className="list-group-item show-cursor"> 
                        <small> {cust?.Name}  </small> | <small> ${cust?.Email_Address} </small></li>
                      </ul>
         
                          // <li onClick={()=> selectFromSearch(cust)} key={i}>
                          //   <div className="dropdown-item  border show-cursor">
                          //     <div  className=""> 
                          //     <small className="" > {cust?.Name} </small> 
                          //     <small>{cust?.Email_Address} </small>
                          //     <small  > {cust?.Customer_Mobile_No} </small> 
                          //     </div>
                              
                          //   </div>
                          //   </li>

               )
                      })
               
                }
                </div>
                </div>
                <div className="p-2 bd-highlight">
                <IVRFilter onFilterSelect={onFilterSelect} />
                </div>
                <div className="p-2 bd-highlight">
                <span className="badge badge-primary p-2 mt-1">{tableList?.length}</span>
                </div>

                <div className="p-2 bd-highlight">
                <button  className='btn btn-light' onClick={()=> clearList()} >Clear</button>
                </div>
              </div>
             
                 <IVRCustomerTable tableList={tableList}  removeFromSelection={removeFromSelection} />
                </div>

               
                     
            </div>
</div>

        </>
     );
}

export default IVRSystemTab;