import { atom , useSetRecoilState, useRecoilValue, selector} from "recoil";



export const productCatAtom = atom({key:'product_category_feature_atm',
    default:{action_url_info:[], product_feature_info:[]}});
export const productUrlEditAtom = atom({key:'product_category_feature_atm_edit',default:{}});

export  const SetProductCatFeatureAtom = () => useSetRecoilState(productCatAtom);
export  const GetProductCatFeatureAtom = () => useRecoilValue(productCatAtom);

// export  const SetProductActionUrlEditAtom = () => useSetRecoilState(productUrlEditAtom);
// export  const GetProductActionUrlEditAtom = () => useRecoilValue(productUrlEditAtom);
