/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import underscore from "underscore";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { editCustomerAtom } from "../store/customerAtom";
import { useRecoilValue } from "recoil";
import { useEffect } from "react";
import LastSection from "./LastSection";
import GeneralSelect from "./GeneralSelect";

function AddCustomerOtherInfo(props) {
  var customerToEdit = useRecoilValue(editCustomerAtom);
  const {register,handleSubmit,reset, setValue, getValues,formState: { errors }} = useForm();
  const [allowCustomerPortal, setAllowCustomerPortal] = useState(false);
  const onErrors = (errors) => console.error(errors);
  const { masterData } = props;

  var masterDataGroup = underscore.groupBy(masterData, "Master_Type");

  if (masterDataGroup) {
    var paymentTermsList = masterDataGroup[4];
    var currencyList = masterDataGroup[5];

    var priceList = masterDataGroup[6];
    var religionList = masterDataGroup[11];
    var professionList = masterDataGroup[10];
    var genderList = masterDataGroup[8];
    var ageList = masterDataGroup[9];
    var assignOfficerList = masterDataGroup[13];
  }






  useEffect(() => {
    if(customerToEdit?.Customer_Id){
    setValue('Currency_Id',  customerToEdit.Currency_Id);
    setValue('Facebook_URL',  customerToEdit.Facebook_URL);
    setValue('Referring_Email',  customerToEdit.Referring_Email);
    setValue('Referring_Person',  customerToEdit.Referring_Person);
    setValue('Customer_Remark',  customerToEdit.Customer_Remark);
    setValue('Date_Of_Birth',  customerToEdit.Date_Of_Birth);
    setValue('Gender_Id',  customerToEdit.Gender_Id);
    setValue('Price_List_Id',  customerToEdit.Price_List_Id);
    setValue('Customer_Remark',  customerToEdit.Customer_Remark);
    setValue('Age_Id',  customerToEdit.Age_Id);
    setValue('Religion_Id',  customerToEdit.Religion_Id);
    setValue('Bus_Stop',  customerToEdit.Bus_Stop);
    setValue('Referring_Person',  customerToEdit.Referring_Person);
    setValue('Referring_Email',  customerToEdit.Referring_Email);
    setValue('Profession_Id',  customerToEdit.Profession_Id);
    setValue('Gender_Id',  customerToEdit.Gender_Id);
    setValue('Date_Of_Birth',  customerToEdit.Date_Of_Birth);
    setValue('officer_id',  customerToEdit.Assigned_Officer_Id);
    setValue('customer_portal_access_id',  customerToEdit.Customer_Portal_Access_Id);

    if(customerToEdit?.Customer_Portal_Access_Id === 1){
      setAllowCustomerPortal(true);
    }else{
      setAllowCustomerPortal(false);

    }
      
    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerToEdit]);

  const onSubmit = () => {
    const formData =  getValues();
    props.submitSection(formData);
  };

  const onSelectCurrency = (currency) => {
    setValue('Currency_Id',  currency.Code);

  };

  const onSelectPaymentTerm = (term) => {
    setValue('Payment_Term_Id',  term.Code);
  };

  const onSelectPriceList = (price) => {
    setValue('Price_List_Id',  price.Code);
  };

  const onSelectReligion = (item) => {
    setValue('Religion_Id',  item.Code);

  };

  const onSelectAge = (item) => {
    setValue('Age_Id',  item.Code);
  };

  const onOfficerSelect = (item) => {
    console.log(item);
    setValue('officer_id',  item.Code);
  };


  const onAllowPortal = (checked) => {
    setAllowCustomerPortal(checked);
    if(checked){
      setValue('customer_portal_access_id',  1);
    }else{
      setValue('customer_portal_access_id',  2);
    }
  };

  const onSelectProfession = (item) => {
    setValue('Profession_Id',  item.Code);
  };
  
  
  const onSelectGender = (item) => {
    setValue('Gender_Id',  item.Code);

  };

  return (
    <>

<form onSubmit={handleSubmit(onSubmit, onErrors)} className="mb-5">

<div className="grid grid-cols-2 gap-8 mt-5">
  {/* <!-- Section 1 --> */}
  
  <div>
  <p className="font-bold text-sm ml-2"> Other Information </p>
    <div className="grid grid-cols-2 gap-4">
      {/* <!-- Row 1 --> */}
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input1">Currency</label>
        <GeneralSelect onOptionSelect={onSelectCurrency} 
                   defaultVal={ customerToEdit?.Currency_Name}  list={currencyList} />
      </div>
      <div>
        <label className="text-sm font-medium text-gray-700" for="input2">Facebook URL</label>
        <input
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[15vw] pl-3"
                    placeholder="facebook url "   name="facebook_url" {...register("Facebook_URL")}/>
      </div>

      {/* <!-- Row 2 --> */}
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input3">Payment Terms</label>
        <GeneralSelect onOptionSelect={onSelectPaymentTerm} 
                   defaultVal={ customerToEdit?.Corporate_Name}  list={paymentTermsList} />
      </div>
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input4">Twitter</label>
        <input className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[15vw] pl-3"
                    placeholder="enter url" name="twitter_url"  {...register("Twitter_URL")} />
      </div>

      {/* <!-- Row 3 --> */}
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input5">PriceList</label>
        <GeneralSelect onOptionSelect={onSelectPriceList} 
                   defaultVal={ customerToEdit?.Price_List_Name}  list={priceList} />
      </div>
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input6">Website</label>
        <input
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[15vw] pl-3"
                    placeholder="enter url"
                    name="customer_url"
                    // {...register("customer_url")}
                  />
        
      </div>

      {/* <!-- Row 4 --> */}
      <div>
    
        <input
                    type="checkbox"
                    checked={allowCustomerPortal}
                    className="bg-inputColor   "
                    placeholder="Enter fax"
                    onChange={(e) => onAllowPortal(e.target.checked)}
                  />
                  <p>Allow customer  portal access</p>
                 
      </div>
 
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input9">Customer Remark</label>
        
        <input {...register("Customer_Remark")}  type="text" className="w-100 text-sm h-[10vh] bg-filterInput  "
                    placeholder="Enter comment/remarks" />
      </div>

    </div>
  </div>

  {/* <!-- Section 2 --> */}
  <div>
    <div className="grid grid-cols-2 gap-4">
      {/* <!-- Row 1 --> */}
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input11">Religion</label>
        <GeneralSelect onOptionSelect={onSelectReligion} 
                   defaultVal={ customerToEdit?.Religion_Name}  list={religionList} />
      </div>
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input12">Profession</label>
        <GeneralSelect onOptionSelect={onSelectProfession} 
                   defaultVal={ customerToEdit?.Profession_Name}  list={professionList} />
      </div>

      {/* <!-- Row 2 --> */}
      <div>
        <label className="text-sm font-medium text-gray-700" for="input13">Bus Stop</label>
        <input
                    name="bustop"
                    {...register("Bus_Stop")}
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[15vw] pl-3"
                    placeholder="enter busStop/landmark"
                  />
      </div>
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input14">Referring Email</label>
        <input
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[15vw] pl-3"
                    placeholder="enter referring email"
                    name="Referring_Email"
                    {...register("Referring_Email")}
                  />
      </div>

      {/* <!-- Row 3 --> */}
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input15">Referring Person</label>
        <input name="refering_person"  {...register("Referring_Person")}
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[15vw] pl-3"
                    placeholder="enter referring person"   />
      </div>
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input16">Assigned_Officer</label>
        <GeneralSelect onOptionSelect={onOfficerSelect} 
                   defaultVal={ customerToEdit?.Assigned_Officer_Name}  list={assignOfficerList} />
      </div>

      {/* <!-- Row 4 --> */}
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input17">Age</label>
        <GeneralSelect onOptionSelect={onSelectAge} 
                   defaultVal={ customerToEdit?.Age_Name}  list={ageList} />
      </div>
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input18">Date Of Birth</label>
        <input
                    className="bg-inputColor w-[20vw] h-[6vh] md:w-[25vw]  h-[6vh] lg:w-[15vw] pl-3"
                    placeholder="example: june 12"
                    type="date"
                    name="birthday"
                    {...register("Date_Of_Birth")}
                  />
      </div>

      {/* <!-- Row 5 --> */}
      <div>
        <label className=" text-sm font-medium text-gray-700" for="input19">Gender</label>
        <GeneralSelect onOptionSelect={onSelectGender} 
                   defaultVal={ customerToEdit?.Gender_Name}  list={genderList} />
      </div>
     
    </div>

    <div className="ml-2 sm:flex lg:flex justify-start w-full">
                <button className="rounded-lg px-10  my-3 py-2 bg-blue-500 text-blue-100">
                  SAVE
                </button>
              </div>
  </div>
</div>
</form>


    </>

   
  );
}

export default AddCustomerOtherInfo;
