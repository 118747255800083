import React from 'react'
import CustomerContext from "../Context/CustomerContext";
import { useEffect } from "react";
import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { customerListAtom } from "../store/customerAtom";
import { itemsService } from "../services/items.service";
import { SetTItemList, GetItemList, SettItemBackupList,GetItemBackupList,
  ResetItemToEdit
} from "../store/itemAtom";
import underscore from "underscore";
// import '../pages/ItemInformation.css'
import './scrollBar.css';



function ItemDetailsSideScroll(props) {
  const setItemListState = SetTItemList();
  const setItemBackupList = SettItemBackupList();
  const itemList = GetItemList();
  const itemMasterList = GetItemBackupList();

  useEffect(()=> {
    if(itemList.length < 1){
      itemsService.getItemsList().then((res) => {
        if(res?.data !== undefined){
          setItemListState(res?.data);
          setItemBackupList(res?.data);
        }
       
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[itemList.length]);





    

  function customFilter(e) {
    const value = e.target.value;
    if (value == 0) {
      return setItemListState(itemMasterList)
    }

   if(value == 1){
    var customFilter = itemMasterList.filter(
      (item) => item.Item_Variety_Id === 2
    );
   return setItemListState(customFilter);
   }

   if(value == 2){
    var customFilter = itemMasterList.filter(
      (item) => item.Item_Variety_Id === 1
    );
   return setItemListState(customFilter);
   }

   if(value == 3){
    var customFilter = itemMasterList.filter(
      (item) => item.Item_Category_Id === 1
    );
    return setItemListState(customFilter);
   }

   if(value == 4){
    var customFilter = itemMasterList.filter(
      (item) => item.Item_Category_Id === 2
    );
    return setItemListState(customFilter);
   }
 
  }

  return (
    <>
        
    <section  className=" scroll   z-0 mt-3	main border-1 h-[100vh]"
    onChange={customFilter}>
      {/* <p className="ml-4 mt-4 text-xs font-bold md:text-sm ">Items Information</p> */}
      <div>
            <button className="md:mr-3 w-120 ">
              {" "}
              <select
                style={{
                  border: "none",
                  borderRadius:"5px",
                  backgroundColor: "#E5E5E5",
                  color: "#45444B",
                }}
                
                className=" mb-4 text-xs">
                <option value={0} className="bg-filterColor">
                  All
                </option>
                <option value={1} className="bg-filterColor">
                  Composite Items
                </option>
                <option value={2} className="bg-filterColor">
                SIngle Items
                </option>
                <option value={3} className="bg-filterColor">
                Raw Materials
                </option>
                <option value={4} className="bg-filterColor">
                Packing Materials
                </option>
              </select>
            </button>
          </div>

      {itemList.map((item, i) => {
        return (
          <div key={i} className="flex hover:bg-filterColor border ">
            <label className="ml-1">
              <input type="checkbox" className="w-[2vh] h-[2vh]" />
            </label>
            <label onClick={()=> props.viewItemDetails(item.Item_Id)} className="ml-2 ">
              <span className="scroll_text ml-1 md:text-sm">
               {item.Item_Name}
              </span>
            </label>
          </div>
        );
      })}
    </section>
    </>
  )
}

export default ItemDetailsSideScroll