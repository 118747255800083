
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const notificationService = {
    getNotifyList,
    getNotifyDetails,
    getCustomerList,
    sendNotificationMessage,
   notificationFilter,
   notificationView,
   searchByDate,
   getSurvey,
   processSurvey,
   getSurveyList,
   submitIVRHistory,
   historyFromDelgro,
   getIVRDetails,
   ivrScheduleList,
   deleteSchedule
};

async function  getNotifyList() {
    return  axios.get(`/notification/notify-main-list`, authHeader());
}



async function  historyFromDelgro(formData) {
    return  axios.post(`/notification/history-from-delgro`, formData, authHeader());
}


async function  ivrScheduleList() {
    return  axios.get(`/notification/ivr-schedule-list`, authHeader());
}


async function  deleteSchedule(key) {
    return  axios.delete(`/notification/ivr-schedule/${key}`, authHeader());
}


async function  submitIVRHistory(formData) {
    return  axios.post(`/notification/submit-ivr-history`, formData, authHeader());
}

async function  getSurvey() {
    return  axios.get(`/notification/get-survey`, authHeader());
}

async function  getSurveyList() {
    return  axios.get(`/notification/get-survey-list`, authHeader());
}

async function  processSurvey(data) {
    return  axios.post(`/notification/process-survey`, data, authHeader());
}

async function  getNotifyDetails(id) {
    return  axios.get(`/notification/notify-details/${id}`, authHeader());
}

async function  getIVRDetails(id) {
    return  axios.get(`/notification/ivr-details/${id}`, authHeader());
}


async function  getCustomerList() {
    return  axios.get(`/notification/notification-customers`, authHeader());
}


async function  sendNotificationMessage(bodyData) {
    return  axios.post(`/notification/send-notification`, bodyData , authHeader());
}


async function  notificationFilter(form) {
    return  axios.post(`/notification/notification-filter`, form, authHeader());
}


async function  notificationView() {
    return  axios.get(`/notification/notification-view`, authHeader());
}


async function  searchByDate(data) {
    return  axios.post(`/notification/search-by-date`, data, authHeader());
}













