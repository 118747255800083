

import React, { useState } from 'react';
import { Button, Space, Switch } from 'antd';
import DelgroHistory from './DelgroHistory';
import ServiceHistory from './ServiceHistory';


function IVRHistoryTab() {
  const [disabled, setDisabled] = useState(true);


    return ( 
        <>
    
  
    <DelgroHistory />
    

  
  

        </>
     );
}

export default IVRHistoryTab;