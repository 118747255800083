/* eslint-disable jsx-a11y/anchor-is-valid */

// import './AddItemPage.css';
import { itemsService } from '../../services/items.service';
import { useEffect, useState } from 'react';
import AddItemForm from './AddItemFormProps/AddItemForm';
import { GetItemToEdit } from '../../store/itemAtom';
import AddItemImage from './Add-items-props/AddItemImage';
import AddAssciates from '../AddAssciates';

const TAB = { FORMTAB: 'FORMTAB', IMAGETAB: 'IMAGETAB', ASSOCIATETAB: 'ASSOCIATETAB' }



function AddItemPage() {
  const [currentTab, setCurrentTab] = useState(TAB.FORMTAB);
  const itemToEditData = GetItemToEdit();
  const [itemToEdit, setItemToEdit] = useState();
  const [allowAssociate, setAllowAssociate] = useState(1);


  useEffect(()=> {
    if(itemToEditData?.Item_Id){
      setItemToEdit(itemToEditData);
    }
  },[itemToEditData]);


  const changeTab = (tab) => {
    console.log('tab ', tab)
    setCurrentTab(tab)
  }




  const submitItemImage = (imageFile, imageId) => {
    let formData = new FormData();
    formData.append('file', imageFile);
    formData.append('Item_Id', itemToEdit.Item_Id);
    formData.append('image_id', imageId);
    itemsService.submitItemImage(formData).then(res => {
      console.info(res?.data);
      var newBody = { Item_Id: itemToEdit.Item_Id, picture_format: res?.data?.imageData?.picture_format, picture_id: imageId }

      submitImageRecord(newBody);
      setCurrentTab(TAB.IMAGETAB)
    }).catch(err => {
    })

  }

  const controlAssociate = (code) => {
    setAllowAssociate(parseInt(code));
  }


  function submitImageRecord(body) {
    itemsService.submitItemImageRecord(body).then(res => {
    })
  }


  function changeTabToAssociateTab(body) {
    setCurrentTab(TAB.ASSOCIATETAB)
  }


  function updateItemId(id) {
    setItemToEdit(prev => { return ({ ...prev, Item_Id: id }) })
  }


  return (
    <>
      <section className="flex my-3  w-45 ">
        <div className="  md:mt-2 flex ">
          <button
            onClick={() => changeTab(TAB.FORMTAB)}
            className={
              "  rounded-lg  px-4 py-2 mx-2 " + (currentTab === TAB.FORMTAB ? "bg-blue-500 text-light " : "bg-neutral-300 text-light")}
          >
            Item Information
          </button>

          {(allowAssociate === 2 && itemToEdit?.Item_Id) ?
            <button
              onClick={() => changeTab(TAB.ASSOCIATETAB)}
              className={
                " rounded-lg px-4 py-2  mx-2 " + (currentTab === TAB.ASSOCIATETAB ?
                  "bg-blue-500 text-light " : "bg-neutral-300 text-light")} >
              Associated Items
            </button>
            : null}



          {itemToEdit?.Item_Id ? (
            <button
              onClick={() => changeTab(TAB.IMAGETAB)}
              className={
                " rounded-lg px-4 py-2  mx-2 " +
                (currentTab === TAB.IMAGETAB ? "bg-blue-500 text-light " : "bg-neutral-300 text-light")}
            >
              Item Pictures
            </button>

          ) : null}

        </div>

      </section>
      {/* AddItemFormImages */}
      {currentTab === TAB.FORMTAB ? <AddItemForm controlAssociate={controlAssociate} 
      changeTabToAssociateTab={() => changeTabToAssociateTab()} updateItemId={(id) => updateItemId(id)} /> : null}

      {currentTab === TAB.ASSOCIATETAB ? < AddAssciates item_id={itemToEdit?.Item_Id} ></AddAssciates> : null}
      {currentTab === TAB.IMAGETAB ? < AddItemImage item_id={itemToEdit?.Item_Id} getItemImages={itemsService.getItemImages} submitItemImage={submitItemImage} /> : null}





    </>
  );
}

export default AddItemPage;