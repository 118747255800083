import { atom , useSetRecoilState, useRecoilValue} from "recoil";




export const ivrMainStoreAtom = atom({key:'ivr_main_history',
    default:{current_page: 0, data:[], last_page:0, per_page:0, from:0, to:0, total:0 }});
export const ivrHistoryStoreAtom = atom({key:'ive_atom_history',default:[]});//


export const SetMainIVRStore = () => useSetRecoilState(ivrMainStoreAtom);
export const GetMainIVRStore = () => useRecoilValue(ivrMainStoreAtom);


export const SetIVRHistoryStore = () => useSetRecoilState(ivrHistoryStoreAtom);
export const GetIVRHistoryStore = () => useRecoilValue(ivrHistoryStoreAtom);
