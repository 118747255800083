import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import pimage from "../assets/plansImage.svg";
import { productService } from "../services/product.service";
import Card from "@mui/material/Card";
import GeneralModal from "../../src/helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { SetPlanEdit } from "../store/productAtom";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import LogicContext from "../Context/LogicContext";
import { imageService } from "../services/image.service";
import CurrencyFormat from "react-currency-format"; 
import { Grid } from "@mui/material";

function ProductPlans(props) {
  const myPromiseModal = createModal(GeneralModal);
  const [temKeep, setTemKeep] = useState([]);
  const [tableData, setTableData] = useState([]);
  const logicContext = useContext(LogicContext);

  const setPlanEdit = SetPlanEdit();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  useEffect(() => {
    if (props?.Product_Id !== undefined) {
      productService.getProductByPlan(props?.Product_Id).then((res) => {
        console.log("Plans ", res.data);
        if (res.data !== undefined) {
          setTableData(res.data);
          setTemKeep(res.data);
        }
      });
    }
  }, [props?.Product_Id]);

  function showInformation() {
    if (props?.Product_Id !== undefined) {
      productService.getProductByPlan(props?.Product_Id).then((res) => {
        if (res.data !== undefined) {
          setTableData(res.data);
        }
      });
    }
  }

  async function editPlan(plan) {
    await myPromiseModal({
      title: "Edit Plan?",
      body: " Do you want to modify this record?",
      showInput: false,
      placeHolder: "",
    }).then((res) => {
      if (res.decision === true) {
        setPlanEdit(plan);
        navigate(
          `/${currModuleId}/${currModuleName}/${currUserMenu}/add-plan/${plan?.Product_Id}`
        );
      }
    });
  }

  function openPanel() {
    if (tableData?.length < 1) {
      showInformation();
    }
  }

  const markAsInActive = async (item) => {
    await myPromiseModal({
      title: "Mark as Inactive!",
      body: " Do you want to mark this Plan as Inactive?",
      showInput: false,
      placeHolder: "",
    }).then((res) => {
      if (res.decision === true) {
        //  send to server
        productService.submitProductPlan({ ...item, option: 4 }).then((res) => {
          if (res.data !== undefined) {
            const { Message_Text, Message_Id } = res.data[0];
            if (Message_Id === 1) {
              logicContext.showToast(Message_Text, "success");
              const newList = tableData.map((plan) => {
                if (plan.Plan_Id === item.Plan_Id) {
                  return {
                    ...plan,
                    Plan_Status_Name: "Inactive",
                    Plan_Status_Id: 2,
                  };
                }
                return plan;
              });
              setTableData(newList);
            } else {
              return logicContext.showToast(Message_Text, "error");
            }
          }
        });
      }
    });
  };

  const markAsActive = async (item) => {
    await myPromiseModal({
      title: "Mark as Active!",
      body: " Do you want to mark this Plan as Active?",
      showInput: false,
      placeHolder: "",
    }).then((res) => {
      if (res.decision === true) {
        //  send to server
        productService.submitProductPlan({ ...item, option: 5 }).then((res) => {
          if (res.data !== undefined) {
            const { Message_Text, Message_Id } = res.data[0];
            if (Message_Id === 1) {
              logicContext.showToast(Message_Text, "success");
              const newList = tableData.map((plan) => {
                if (plan.Plan_Id === item.Plan_Id) {
                  return {
                    ...plan,
                    Plan_Status_Name: "Active",
                    Plan_Status_Id: 1,
                  };
                }
                return plan;
              });
              setTableData(newList);
            } else {
              return logicContext.showToast(Message_Text, "error");
            }
          }
        });
      }
    });
  };

  function searchProduct(search) {
    if (search.length < 3) {
      setTableData(temKeep);
    } else {
      var tempResult = temKeep.filter((item) => {
        return (
          item?.Product_Name?.toLowerCase().indexOf(search.toLowerCase()) >=
            0 ||
          item?.Plan_Name?.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
          item?.Item_Sku?.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
          item?.Plan_Price.toString()
            ?.toLowerCase()
            .indexOf(search.toLowerCase()) >= 0 ||
          item?.Plan_Features?.toLowerCase().indexOf(search.toLowerCase()) >= 0
        );
      });

      setTableData(tempResult);
    }
  }

  return (
    <>
      <section className="my-2 ">
        <Accordion expanded={true}>
          <AccordionSummary
            onClick={openPanel}
            style={{ backgroundColor: "#E7ECFF" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="font-weight-bold"> Plans</Typography>
            {/* SEARCH BAR */}
            <div className="ml-5 border-[1px]">
              <input
                onChange={(ev) => searchProduct(ev.target.value)}
                className="px-4 text-sm bg-inputColor  py-2 h-[5vh] w-100"
                placeholder="Search product"
              />
            </div>
          </AccordionSummary>

          <AccordionDetails>
            {tableData?.map((item, i) => {
              return (
         

                <Grid
                  key={i}
                  className="border-2 my-2 py-2 px-1"
                  container
                  spacing={2}
                >
                  <Grid item xs={2}>
                    <div className="">
                      <img
                        src={
                          imageService.PRODUCT_PLAN_IMAGE_URL +
                          item.Image_Reference_No +
                          "." +
                          item.Image_Format
                        }
                        alt=""
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="">
                      <div>
                        <span
                          className=" text-sm font-bold"
                          style={{ color: "#45444B" }}
                        >
                          {item?.Plan_Name}{" "}
                        </span>{" "}
                        :{" "}
                        <span style={{ color: "#45444B" }}>
                          {" "}
                          <span className="text-sm">
                            Plan Code: {item?.Plan_Id}{" "}
                          </span>{" "}
                        </span>
                      </div>
                      {/* <div>
                          <span style={{ color: "#45444B" }}> <i style={{ fontSize: "15px" }} >Plan Code: {item?.Plan_Id} </i> </span>
                        </div> */}
                      <div>
                        <span className="text-sm font-bold ">
                          Associated Addons:{" "}
                        </span>
                        <span style={{ color: "#45444B" }}>
                          {" "}
                          <span className="text-sm">
                            {item?.Plan_Features}{" "}
                          </span>
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="">
                      {item?.Plan_Status_Id === 1 ? (
                        <span className="text-sm">
                          Status:
                          <button className="badge text-sm badge-success  font-weight-bold">
                            {" "}
                            {item?.Plan_Status_Name}{" "}
                          </button>
                        </span>
                      ) : (
                        <span className="text-sm">
                          Status:{" "}
                          <button className="badge text-sm badge-light  font-weight-bold">
                            {item?.Plan_Status_Name}
                          </button>{" "}
                        </span>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container className=" " spacing={2}>
                      <Grid item xs={12}>
                        <div className="flex - justify-center">
                          <span className=" font-bold text-xl">
                            <CurrencyFormat
                              value={item?.Plan_Price?.toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"₦"}
                            />
                          </span>
                          :
                          <span
                            style={{ color: "#45444B" }}
                            className="text-sm mt-1"
                          >
                            {item?.Bill_Duration_Type_Name}{" "}
                          </span>
                        </div>
                      </Grid>

                      <Grid item className="flex justify-between" xs={12}>
                        <button
                          className=" py-0.5 px-1  rounded text-sm text-white bg-yellow-300"
                          onClick={() => editPlan(item)}
                        >
                          Edit
                        </button>
                        {item?.Plan_Status_Id === 1 ? (
                          <button
                            className="py-0.5 px-1 mx-2 rounded text-sm text-white bg-orange-300 "
                            onClick={() => markAsInActive(item)}
                          >
                            Mark as Inactive
                          </button>
                        ) : null}

                        {item?.Plan_Status_Id === 2 ? (
                          <button
                            className="py-0.5 px-1  rounded text-sm text-white bg-green-500"
                            onClick={() => markAsActive(item)}
                          >
                            Mark as Active
                          </button>
                        ) : null}

                        {/* <button
                          className="py-0.5 px-3  rounded text-sm text-white bg-red-900"
                          style={{ color: "#F56132" }}
                        >
                          Delete
                        </button> */}
                      </Grid>
                    </Grid>
                  </Grid>

                 
                </Grid>
              );
            })}
          </AccordionDetails>
        </Accordion>
      </section>

      {/* {!showTable ?
    <button className="btn btn-sm btn-primary ml-4 mt-5" onClick={showInformation}> View Associate Items</button>
  : null  } */}
    </>
  );
}

export default ProductPlans;
