
import { transactionService } from "../../services/transaction.service";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import underscore from "underscore";
import { useRecoilValue } from "recoil";
import { transactionmasterDataAtom } from "../../store/transAtom";
import { Table, Input } from 'antd';
const { Search } = Input;




function InvoiceContent(props) {
  const [invoiceList, setInvoiceList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const masterData = useRecoilValue(transactionmasterDataAtom);
  var masterDataGroup = underscore.groupBy(masterData, "Master_Type");
  const invoiceStatus = masterDataGroup[3];
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];



  useEffect(() => {
    transactionService.getTransactionInvoice(props.customer_id).then((res) => {
      let listInvoice = [];
      console.log(res?.data);
      if (res?.data !== undefined) {
        listInvoice = res?.data?.map((invoice, index) => {
          return { ...invoice,  Amount: invoice?.Amount?.toFixed(2), Invoice_Ref_No: invoice.Invoice_Ref_No.toString(),
            Order_NO: invoice?.Order_NO.toString()
           };
        });
      }
      console.log('MNM_', listInvoice);
      setInvoiceList(listInvoice);
      setFilteredData(listInvoice);
    });
  }, [props.customer_id]);

  function statusSelectHandler(value) {
    if (value === "All") {
      return setFilteredData(invoiceList);
    } else {
      var tempList = invoiceList?.filter(
        (item) => item.Invoice_Status === value
      );
      setFilteredData(tempList);
    }
  }


  function OnItemCellClick(event) {
    const id = event.row["Invoice_Ref_No"];
    navigate(`/${currModuleId}/${currModuleName}/invoices/details/${id}`);
  }


  const paginationOptions = {
    pageSize: 10,
    showSizeChanger: true,
    total: filteredData.length,
    // onChange: (page, pageSize) => {
    //   console.log(page, pageSize);
    //   const newList = tableDataList.slice(0, pageSize);
    //   console.log('check ', newList);
    //   setTableList(newList);
    // },
  };


  const tableHead = [
    { field: "Invoice_Date", headerName: "Date", width: 130 },
    { field: "Invoice_No", headerName: "Invoice No", width: 130 },
    { field: "Order_NO", headerName: "Order No", width: 130 },
    { field: "Amount", headerName: "Amount", width: 130 },
    { field: "Invoice_Status", headerName: "Status", width: 130 },
  ];

const columns = [
  {
    title: 'Invoice Ref',
    // key: 'Order_NO',
    dataIndex: 'Invoice_Ref_No',
    sorter: (a, b) => a.Invoice_Ref_No.localeCompare(b.Invoice_Ref_No)
  //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
  },

  {
    title: "Invoice Number",
    dataIndex: "Invoice_No",
    sorter: (a, b) => a.Invoice_No.localeCompare(b.Invoice_No)
  },

  {
    title: "Order Number",
    dataIndex: "Order_NO",
    sorter: (a, b) => a.Order_NO.localeCompare(b.Order_NO)
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: (a, b) => a.Amount.localeCompare(b.Amount)
  },
  {
    title: "Status",
    dataIndex: "Invoice_Status",
    sorter: (a, b) => a.Invoice_Status.localeCompare(b.Invoice_Status)
  },
];

const handleSearch = (value) => {
  setSearchText(value);
  const filtered = invoiceList.filter((record) =>
    Object.values(record).some((text) =>
      String(text).toLowerCase().includes(value.toLowerCase())
    )
  );
  setFilteredData(filtered);
};

const handleRowClick = (record) => {
  console.log('Row clicked:', record);
  // navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${record?.IVR_Id}`);
};





  return (
    <>
      <div className="ml-1 @container">
        <div className="mt-1 text-gray-600 text-xl font-bold">Invoice</div>
        <div className="mt-2 space-y-3 @lg:flex @lg:flex-row @lg:items-center @lg:justify-between @lg:space-y-0 @lg:space-x-2">
        
          <div className="flex flex-row items-end space-x-2">
            <div className="flex flex-row items-center mb-4 space-x-2">
              <div className="text-sm text-gray-600">Status:</div>
              <select
                onChange={(ev) => statusSelectHandler(ev.target.value)}
                className="rounded-md w-[130px]"
              >
                <optgroup className="bg-white rounded-md">
                  {invoiceStatus?.reverse().map((status, i) => {
                    return <option value={status.Name}> {status.Name} </option>;
                  })}
                </optgroup>
              </select>
              {/* <button className="text-sm font-medium text-[#476EF8] hover:underline hover:underline-offset-2">
                Add New
              </button> */}
            </div>
          </div>
        </div>

      
        <Search
        placeholder="Search..."
        onSearch={handleSearch}
        onChange={(e) => handleSearch(e.target.value)}
        value={searchText}
        style={{ marginBottom: 10, width: 250 }}
      />

        <Table key={filteredData?.length} columns={columns} dataSource={filteredData}
                      pagination={paginationOptions}
                      rowClassName="show-cursor"
                      style={{ background: '#f0f2f5', borderRadius: '8px' }}
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            handleRowClick(record);
                          },
                        };
                      }}
                        />
     
      </div>
    </>
  );
}

export default InvoiceContent;
