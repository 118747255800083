import { Route, Routes } from "react-router-dom";
import Userlayout from "../layout/Userlayout";
import AddCustomerPage from "../pages/AddCustomerPage";
import CustomersPage from "../pages/customer-page/CustomersPage";
import InventoryDashboard from "../pages/InventoryDashboard";
import NotFoundPage from "../pages/NotFoundPage";
import { PrivateRoute } from "./PrivateRoutes";
import ItemGroupPage from "../pages/ItemGroupPage";
import ItemPage from "../pages/ItemPage";
import AddItemPage from "../pages/item-page/AddItemPage";
import ItemDetailsPage from "../pages/ItemDetailsPage";
import AddItemGroup from "../pages/AddItemGroup";
import ItemDetailsGroupPage from "../pages/ItemDetailsGroupPage";
import AddCorporatePage from "../pages/corprate_page/AddCorporatePage";

import CompositeItemGroupPage from "../pages/CompositeItemGroupPage";
import CompositeItemGroupAddNew from "../pages/item-page/AddItemFormProps/CompositeitemGroupAddNew";
import CompositeGroupDetailsPage from "../pages/CompositeGroupDetailsPage";
import ProductionPage from "../pages/ProductionPage";
import ProductionDetailsPage from "../pages/product/ProductionDetailsPage";
import InvoicePage from "../pages/invoice_page/InvoicePage";
import AddInvoicePage from "../pages/invoice_page/AddInvoicePage";
import CustomerDetails from "../pages/customer-page/CustomerDetails";
import PurchaseOrderDetailsPage from "../pages/purchase_order/PurchaseOrderDetailsPage";
import VendorPage from "../pages/vendor/VendorPage";
import AddVendorPage from "../pages/vendor/AddVendorPage";
import VendorDetailsPage from "../pages/vendor/VendorDetailsPage";
import BillPage from "../pages/bill/BillPage";
import AddBillPage from "../pages/bill/AddBillPage";
import BillsDetailsPage from "../pages/bill/BillsDetailsPage";
import InvoiceDetailsPage from "../pages/invoice_page/InvoiceDetailsPage";
import PaymentReceivedPage from "../pages/payment_receive/PaymentReceivedPage";
import AddPaymentReceived from "../pages/payment_receive/AddPaymentReceived";
import PaymentReceivedDetailsPage from "../pages/payment_receive/PaymentReceivedDetailsPage";
import PurchaseOrderPage from "../pages/purchase_order/PurchaseOrderPage";
import AddPurchaseOrderPage from "../pages/purchase_order/AddPurchaseOrderPage";
import SalesOrderPage from "../pages/sales_order/SalesOrderPage";
import AddSalesOrderPage from "../pages/sales_order/AddSalesOrderPage";
import SalesOrderDetailsPage from "../pages/sales_order/SalesOrderDetailsPage";
import PurchaseReceivePage from "../pages/purchase_receive/PurchaseReceivePage";
import AddPurchaseReceivePage from "../pages/AddPurchaseReceivePage";
import PurchaseReceiveDetailsPage from "../pages/purchase_receive/PurchaseReceiveDetailsPage";
import ImageUploaderPage from "../pages/image_upload/ImageUploaderPage";
import PriceListPage from "../pages/price_list/PriceListPage";
import PriceListDetailsPage from "../pages/price_list/PriceListDetailsPage";
import CustomerApprovalPage from "../pages/customer_approval/customerApprovalPage";
import CustomerApproveDetails from "../pages/customer_approval/customerApprovedetails";
import CorporatePage from "../pages/corprate_page/CorporatePage";
import CorporateDetailsPage from "../pages/corprate_page/CorporateDetailsPage";
import VoucherCorporateMainPage from "../pages/vouchers-corporate/VoucherCorporateMainPage";
import AddCorporateVoucherPage from "../pages/vouchers-corporate/AddCorporateVoucherPage";
import VoucherCorDetailsPage from "../pages/vouchers-corporate/VoucherCorDetailsPage";
import VoucherOfflinePage from "../pages/voucher-offline/VoucherOfflinePage";
import OfflineVoucherRedeem from "../pages/voucher-offline/OfflineVoucherRedeem";
import OfflineDetailsPage from "../pages/voucher-offline/OfflneDetailsPage";
import RatingMainPage from "../pages/rating/RatingMainPage";
import RatingDetailsPage from "../pages/rating/RatingDetailsPage";
import CorporateApprovalPage from "../pages/corporate_approval/CorporateApprovalPage";
import CorporateAppDetailsPage from "../pages/corporate_approval/CorperateAppDetailsPage";
import RewardCenterSetup from "../pages/reward_center/RewardCenterSetup";
import RewardHistoryPage from "../pages/reward_history/RewardHistoryPage";
import RewardHistoryDetails from "../pages/reward_history/RewardHistorydetails";
import RewardRedeemPage from "../pages/reward_redeeem/RewardRedeemPage";
import CarrierBagPage from "../pages/carreer_bag/CarrierBagPage";
import CarrierDetailsPage from "../pages/carreer_bag/CarrierPageDetails";
import CarrierBagAddNewPage from "../pages/carreer_bag/CarrierBadAddNewPage";



function InventoryRoutes() {
    return ( 
        <>
      <Userlayout>
        <Routes>
       <Route  path='customers' element={
                <PrivateRoute> <CustomersPage /></PrivateRoute>}>
          </Route>

            {/* customer page children routes */}
            <Route exact  path='/customers/customer-details/:customer_id' element={
                  <PrivateRoute> <CustomerDetails /> </PrivateRoute>}>
            </Route>
            <Route  path='/customers/add_customer' element={
                  <PrivateRoute> <AddCustomerPage /> </PrivateRoute>}>
            </Route>


          <Route exact  path='/customer-approval' element={
                <PrivateRoute> <CustomerApprovalPage /> </PrivateRoute>}>
          </Route>
          <Route exact  path='/customer-approval/details/:customer_id' element={
                <PrivateRoute> <CustomerApproveDetails /> </PrivateRoute>}>
          </Route> 


        
          <Route exact  path='/dashboard' element={
                <PrivateRoute> <InventoryDashboard />  </PrivateRoute>}>
          </Route>
          <Route exact  path='/dashboard/customer-details/:customer_id' element={
                  <PrivateRoute> < CustomerDetails/>  </PrivateRoute>}>
            </Route>
        
          <Route exact  path='/composite-item-groups' element={
                <PrivateRoute> <CompositeItemGroupPage />  </PrivateRoute>}>
          </Route>
          <Route exact  path='/composite-item-groups/add-new' element={
                <PrivateRoute> <CompositeItemGroupAddNew />  </PrivateRoute>}>
          </Route>
          <Route exact  path='/composite-item-groups/details/:group_id' element={
                <PrivateRoute> <CompositeGroupDetailsPage />  </PrivateRoute>}>
          </Route>
        
        
          <Route exact  path='/item-groups' element={
                <PrivateRoute> <ItemGroupPage /> </PrivateRoute>}>
          </Route>

            <Route  path='/item-groups/add-item-group' element={
            <PrivateRoute><AddItemGroup /> </PrivateRoute>}>
            </Route>

            <Route  path='/item-groups/item-group-details/:item_group_id' element={
                  <PrivateRoute> <ItemDetailsGroupPage />  </PrivateRoute>}>
            </Route>
        
          <Route exact  path='/items' element={
                <PrivateRoute> <ItemPage />  </PrivateRoute>}>
          </Route>

            <Route  path='/items/add-item/:option' element={
                  <PrivateRoute>  <AddItemPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/items/item-details/:item_id' element={
                  <PrivateRoute>  <ItemDetailsPage /> </PrivateRoute>}>
            </Route>
            
            <Route exact  path='/production' element={
                <PrivateRoute> <ProductionPage /> </PrivateRoute>}>
          </Route>  
            
            <Route exact  path='/production/details/:batch_id' element={
                <PrivateRoute> <ProductionDetailsPage /> </PrivateRoute>}>
          </Route>    

          <Route exact  path='/invoices' element={
                <PrivateRoute> <InvoicePage /> </PrivateRoute>}>
          </Route>  

          <Route exact  path='/invoices/add-invoice' element={
                <PrivateRoute> <AddInvoicePage /> </PrivateRoute>}>
          </Route> 

                  
          <Route exact  path='/invoices/details/:invoice_id' element={
                <PrivateRoute> <InvoiceDetailsPage /> </PrivateRoute>}>
          </Route> 


          <Route exact path='/grocedy-rating' element={
                  <PrivateRoute> <RatingMainPage /> </PrivateRoute>}>
            </Route>
            
            <Route exact path='/grocedy-rating/details/:id' element={
                  <PrivateRoute> <RatingDetailsPage /> </PrivateRoute>}>
            </Route>


            <Route exact path='/corporate-approval' element={
                  <PrivateRoute> <CorporateApprovalPage /> </PrivateRoute>}>
            </Route>
            
            <Route exact path='/corporate-approval/details/:corporate_id' element={
                  <PrivateRoute> <CorporateAppDetailsPage /> </PrivateRoute>}>
            </Route>
             <Route  path='/Setup' element={
                  <PrivateRoute>  < RewardCenterSetup/> </PrivateRoute>}>
            </Route>

          <Route  path='/reward' element={
                  <PrivateRoute>  < RewardHistoryPage/> </PrivateRoute>}>
            </Route>

          <Route  path='/reward/details/:id' element={
                  <PrivateRoute>  < RewardHistoryDetails/> </PrivateRoute>}>
            </Route>

           {/* Reward redeem */}
           <Route  path='/reward-redeem' element={
                  <PrivateRoute>  < RewardRedeemPage/> </PrivateRoute>}>
            </Route>

            <Route  path='/carrier-bag-return' element={
                  <PrivateRoute>  < CarrierBagPage/> </PrivateRoute>}>
            </Route>

           <Route  path='/carrier-bag-return/details/:id' element={
                  <PrivateRoute>  < CarrierDetailsPage/> </PrivateRoute>}>
            </Route>


           <Route  path='/carrier-bag-return/add-new' element={
                  <PrivateRoute>  < CarrierBagAddNewPage/> </PrivateRoute>}>
            </Route>

          

          <Route exact  path='/payment-received' element={
                <PrivateRoute> <PaymentReceivedPage /> </PrivateRoute>}>
          </Route>
          <Route exact  path='/payment-received/add-payment-received' element={
                <PrivateRoute> <AddPaymentReceived /> </PrivateRoute>}>
          </Route>


          <Route exact  path='/payment-received/details/:payment_ref_id' element={
                <PrivateRoute> <PaymentReceivedDetailsPage /> </PrivateRoute>}>
          </Route> 

          <Route  path='/purchase-order' element={
                  <PrivateRoute>  <PurchaseOrderPage /> </PrivateRoute>}>
            </Route>


            <Route  path='/purchase-order/details/:purchase_id' element={
                  <PrivateRoute>  <PurchaseOrderDetailsPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/purchase-order/add-new' element={
                  <PrivateRoute>  <AddPurchaseOrderPage /> </PrivateRoute>}>
            </Route>


            <Route  path='/vendors' element={
                  <PrivateRoute>  <VendorPage /> </PrivateRoute>}>
            </Route>
            <Route  path='/vendors/add-new' element={
                  <PrivateRoute>  <AddVendorPage /> </PrivateRoute>}>
            </Route>
            <Route  path='/vendors/details/:vendor_id' element={
                  <PrivateRoute>  <VendorDetailsPage /> </PrivateRoute>}>
            </Route>


            <Route  path='/bills' element={
                  <PrivateRoute>  <BillPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/image-upload' element={
                  <PrivateRoute>  <ImageUploaderPage /> </PrivateRoute>}>
            </Route>
            <Route  path='/bills/add-new' element={
                  <PrivateRoute>  <AddBillPage /> </PrivateRoute>}>
            </Route>
            <Route  path='/bills/details/:bill_id' element={
                  <PrivateRoute>  <BillsDetailsPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/purchase-receives' element={
                  <PrivateRoute>  <PurchaseReceivePage /> </PrivateRoute>}>
            </Route>
            <Route  path='/purchase-receives/add-new' element={
                  <PrivateRoute>  <AddPurchaseReceivePage /> </PrivateRoute>}>
            </Route>
            
            <Route  path='/purchase-receives/details/:purchase_id' element={
                  <PrivateRoute>  <PurchaseReceiveDetailsPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/sales-order' element={
                  <PrivateRoute>  <SalesOrderPage /> </PrivateRoute>}>
            </Route>
            <Route  path='/sales-order/add-new' element={
                  <PrivateRoute>  <AddSalesOrderPage /> </PrivateRoute>}>
            </Route>
            <Route  path='/sales-order/details/:sales_id' element={
                  <PrivateRoute>  <SalesOrderDetailsPage /> </PrivateRoute>}>
            </Route>

 
            <Route  path='/price-list' element={
                  <PrivateRoute>  <PriceListPage />  </PrivateRoute>}>
            </Route>
          
            <Route  path='/price-list/details/:id' element={
                  <PrivateRoute> <PriceListDetailsPage /> </PrivateRoute>}>
            </Route>

            <Route exact  path='/corporate' element={
                <PrivateRoute> <CorporatePage /> </PrivateRoute>}>
          </Route>
          <Route exact  path='/corporate/add-new' element={
                <PrivateRoute> <AddCorporatePage /> </PrivateRoute>}>
          </Route>
          <Route exact  path='/corporate/details/:corporate_id' element={
                <PrivateRoute> <CorporateDetailsPage /> </PrivateRoute>}>
          </Route>


          <Route  path='/corporate-voucher' element={
                  <PrivateRoute>  < VoucherCorporateMainPage/> </PrivateRoute>}>
            </Route>
            <Route  path='/corporate-voucher/add-new' element={
                  <PrivateRoute>  < AddCorporateVoucherPage/> </PrivateRoute>}>
            </Route>
            <Route  path='/corporate-voucher/details/:id' element={
                  <PrivateRoute>  < VoucherCorDetailsPage/> </PrivateRoute>}>
            </Route>


            <Route  path='/redeem-offline-voucher' element={
                  <PrivateRoute>  < VoucherOfflinePage/> </PrivateRoute>}>
            </Route>

            <Route  path='/redeem-offline-voucher/add-new' element={
                  <PrivateRoute>  < OfflineVoucherRedeem/> </PrivateRoute>}>
            </Route>
            
            <Route  path='/redeem-offline-voucher/details/:id' element={
                  <PrivateRoute>  < OfflineDetailsPage/> </PrivateRoute>}>
            </Route>



         
        <Route path="/*" element={<NotFoundPage />}></Route>
        </Routes>
        </Userlayout>

        </>
     );
}

export default InventoryRoutes;